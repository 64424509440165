<template>
  <div>
    <a-modal
        :width="500"
        v-model="showPopup"
        :keyboard="false"
        title="取消发货"
        ok-text="确认"
        cancel-text="取消"
        @cancel="handleCancel"
        @ok="handleConfirm"
    >
      <a-form-model
          ref="ruleForm"
          :model="params"
          v-bind="layout"
      >
        <a-form-item label="是否退还发货运费">
          <a-radio-group
              name="radioGroup"
              v-model="params.isRefundExpressCost"
          >
            <a-radio-button :value="false">不退还</a-radio-button>
            <a-radio-button :value="true">退还</a-radio-button>
          </a-radio-group>
        </a-form-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import {cancelUserSendProductApi} from "@/views/cmsPage/shopManage/senderLogisticsList/_apis"

export default {
  data() {
    return {
      showPopup: false,
      params: {
        orderId: '',
        orderType: '',
        isRefundExpressCost: undefined,
        isShowRefundExpressCost: undefined,
      },
      layout: {
        labelCol: { span: 8 }, //标签占5份  共24份
        wrapperCol: { span: 16 },
      },
      isLoading: false,
    };
  },
  computed: {
  },
  methods: {
    /** 展示 */
    show(data) {
      this.params.orderId = data.orderId
      this.params.orderType = data.orderType
      this.params.isShowRefundExpressCost = data.isShowRefundExpressCost
      this.showPopup = true
    },
    /** 确定取消 */
    async handleConfirm() {
      if (this.params.isRefundExpressCost === undefined) return this.$message.warn('请选择是否需要退还发货运费')
      this.$loading.show()
      const res = await cancelUserSendProductApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.success(res.message || '取消成功')
      this.$emit('editor')
      this.handleCancel()
    },
    /** 取消 */
    handleCancel() {
      this.params = {
        orderId: '',
        orderType: '',
        isRefundExpressCost: undefined,
        isShowRefundExpressCost: undefined,
      }
      this.showPopup = false
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
