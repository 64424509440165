var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: { width: 900, title: "物流信息", footer: null },
          on: { cancel: _vm.handleCancel },
          model: {
            value: _vm.showPopup,
            callback: function($$v) {
              _vm.showPopup = $$v
            },
            expression: "showPopup"
          }
        },
        [
          _c("div", { staticClass: "logistics-content" }, [
            _vm.popupData
              ? _c("div", { staticClass: "content-top" }, [
                  _c("div", [
                    _c("div", { staticClass: "top-user-info" }, [
                      _c("div", { staticClass: "top-user-text ml-20" }, [
                        _vm._v("物流公司："),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.expressCompanyCodeMapText[_vm.popupData.com]
                            )
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "top-user-text ml-20" }, [
                        _vm._v("物流单号："),
                        _c("span", [_vm._v(_vm._s(_vm.popupData.nu))])
                      ])
                    ])
                  ])
                ])
              : _vm._e(),
            _vm.popupData && _vm.popupData.data
              ? _c(
                  "div",
                  { staticClass: "content-time-line" },
                  [
                    _c(
                      "a-timeline",
                      _vm._l(_vm.popupData.data, function(item, index) {
                        return _c(
                          "a-timeline-item",
                          {
                            key: index,
                            attrs: { color: index === 0 ? "blue" : "gray" }
                          },
                          [
                            _c(
                              "p",
                              { class: index === 0 ? "color-black" : "" },
                              [_vm._v(_vm._s(item.context))]
                            ),
                            _c("p", { staticClass: "color-gary" }, [
                              _vm._v(_vm._s(item.time))
                            ])
                          ]
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              : _c("div", { staticClass: "color-gary null-data" }, [
                  _vm._v("暂无物流信息~")
                ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }