<template>
  <div class="logistics-address-list-pages">
<!--  搜索  -->
    <div class="top-search-btn">
      <a-input allowClear v-model="searchOrderNo" class="w-200" placeholder='搜索订单'></a-input>
      <a-button class="ml-20" type="primary" @click="getInit">搜索</a-button>
      <span v-if="orderStatusInfo.orderNo" class="ml-40">订单：【<span class="cur-pot color-blue" @click="handleGoToOrderLis">{{ orderStatusInfo.orderNo }}</span>】</span>
      <span v-if="orderStatusInfo.statusDesc" class="color-black ml-10">{{ orderStatusInfo.statusDesc }}</span>
    </div>
<!--  内容列表  -->
    <LogisticsAddressList :list="logisticsAddressList" @editor="getInit"/>
  </div>
</template>
<script>
import {getLogisticsInfoApi} from "@/views/cmsPage/shopManage/senderLogisticsList/_apis"
import LogisticsAddressList
  from "@/views/cmsPage/shopManage/senderLogisticsList/_components/LogisticsAddressList/index.vue"
import {goToOtherPage} from "@/untils/otherEvent"

export default {
  components: {LogisticsAddressList},
  data() {
    return {
      // 物流列表
      logisticsAddressList: [],
      // 搜索orderNo
      searchOrderNo: '',
      orderStatusInfo: {
        orderNo: '',
        statusDesc: ''
      }
    }
  },
  methods: {
    /** 去往订单货退款订单列表 */
    handleGoToOrderLis() {
      const url = `/cms/dqplatform/ordermannage?orderNo=${this.orderStatusInfo.orderNo}`
      goToOtherPage(url)
    },
    /** 搜索 */
    async getInit() {
      if (!this.searchOrderNo) return this.$message.warn('请输入订单号')
      const res = await getLogisticsInfoApi({orderNo: this.searchOrderNo})
      if (res.status !== '200') return
      this.logisticsAddressList = res.data.logisticsListVoList
      this.orderStatusInfo.statusDesc = res.data.statusDesc
      this.orderStatusInfo.orderNo = res.data.orderNo
    }
  }
}
</script>
<style scoped lang="scss">
.top-search-btn {
  position: sticky;
  top: 0;
  z-index: 9;
  width: 100%;
  background-color: #f2f2f2;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.logistics-address-list-pages {
  width: 100%;
  flex: 1;
  overflow-y: auto;
}
</style>