
/** 订单类型 1直发 2评级 3售后单 */
export const ORDER_TYPE = {
    1: '直发订单',
    2: '评级订单',
    3: '售后订单'
}
export const ORDER_TYPE_CLASS_TYPE = {
    1: 'color-black',
    2: 'color-orange',
    3: 'color-red'
}

/** 发货支付方式
 * MANUAL 线下-手工发货，
 * SHIPPER 线上-寄方付，
 * CONSIGNEE 线上-到付，
 * MONTHLY 线上-月结，
 * LOGISTICS_ACCOUNT 线上-快递费户头，
 * null 线下
 * */
export const SEND_PRODUCT_PAY_TYPE = {
    MANUAL: '线下-手动发货',
    SHIPPER: '线上-寄付现结',
    CONSIGNEE: '线上-发货到付',
    MONTHLY: '线上-寄付月结',
    LOGISTICS_ACCOUNT: '线上-快递费户头支付'
}
/** 央视类 */
export const SEND_PRODUCT_PAY_TYPE_CLASS_TYPE = {
    MANUAL: 'color-gray',
    SHIPPER: 'color-black',
    CONSIGNEE: 'color-green',
    MONTHLY: 'color-orange',
    LOGISTICS_ACCOUNT: 'color-blue'
}