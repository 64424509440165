var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { width: 1000, title: "物流列表", footer: null },
      on: { cancel: _vm.handleCancel },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c(
        "div",
        { staticClass: "w-100-w logistics-popup-list" },
        [
          _vm._l(_vm.listData, function(item, index) {
            return _c(
              "a-card",
              { key: index, staticClass: "w-100-w log-item" },
              [
                _c(
                  "div",
                  {
                    class: item.isOnline ? "color-blue" : "color-red",
                    attrs: { slot: "title" },
                    slot: "title"
                  },
                  [_vm._v(_vm._s(item.isOnline ? "上门取件" : "线下手动"))]
                ),
                _c("div", { staticClass: "flex-start-center font-size-16" }, [
                  _c(
                    "span",
                    {
                      staticClass: "font-weight-bold font-size-16 color-black"
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.expressCompanyCodeMapText[item.logisticsName] ||
                            item.logisticsName
                        )
                      )
                    ]
                  ),
                  _vm._v(" 【"),
                  _c(
                    "span",
                    {
                      staticClass: "font-weight-bold font-size-16 color-green"
                    },
                    [_vm._v(_vm._s(item.expressNo))]
                  ),
                  _vm._v("】 "),
                  _c(
                    "span",
                    {
                      staticClass: "cur-pot color-blue",
                      on: {
                        click: function($event) {
                          return _vm.handleShowLogisticsLine(item)
                        }
                      }
                    },
                    [_vm._v("点击查看物流信息")]
                  )
                ]),
                item.isOnline
                  ? _c(
                      "div",
                      { staticClass: "flex-start-center font-size-16 mt-10" },
                      [
                        item.logisticsTotalAmount
                          ? _c("span", [
                              _vm._v("快递费总金额："),
                              _c(
                                "span",
                                { staticClass: "font-weight-bold color-red" },
                                [
                                  _vm._v(
                                    "￥" + _vm._s(item.logisticsTotalAmount)
                                  )
                                ]
                              )
                            ])
                          : _vm._e(),
                        item.commodityValue
                          ? _c("span", { staticClass: "ml-20" }, [
                              _vm._v("保价金额："),
                              _c(
                                "span",
                                { staticClass: "font-weight-bold color-red" },
                                [_vm._v("￥" + _vm._s(item.commodityValue))]
                              )
                            ])
                          : _vm._e(),
                        item.txnSeqno
                          ? _c("span", { staticClass: "ml-20" }, [
                              _vm._v("交易单号："),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "font-weight-bold color-orange cur-pot"
                                },
                                [_vm._v(_vm._s(item.txnSeqno))]
                              )
                            ])
                          : _vm._e(),
                        item.logisticsPaymentStatus
                          ? _c("span", { staticClass: "ml-20" }, [
                              _vm._v("支付状态："),
                              _c(
                                "span",
                                { staticClass: "font-weight-bold color-red" },
                                [_vm._v(_vm._s(item.logisticsPaymentStatus))]
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  : _vm._e(),
                item.isOnline
                  ? _c(
                      "div",
                      { staticClass: "flex-start-center font-size-16 mt-10" },
                      [
                        item.courierName
                          ? _c("span", [
                              _vm._v("快递员姓名："),
                              _c(
                                "span",
                                { staticClass: "font-weight-bold color-black" },
                                [_vm._v(_vm._s(item.courierName))]
                              )
                            ])
                          : _vm._e(),
                        item.courierMobile
                          ? _c("span", { staticClass: "ml-20" }, [
                              _vm._v("快递员电话："),
                              _c(
                                "span",
                                { staticClass: "font-weight-bold color-blue" },
                                [_vm._v(_vm._s(item.courierMobile))]
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  : _vm._e(),
                item.isOnline
                  ? _c(
                      "div",
                      { staticClass: "flex-start-center font-size-16 mt-10" },
                      [
                        item.statusDesc
                          ? _c("span", [
                              _vm._v("快递状态："),
                              _c(
                                "span",
                                { staticClass: "font-weight-bold color-black" },
                                [_vm._v(_vm._s(item.statusDesc))]
                              )
                            ])
                          : _vm._e(),
                        item.cancelMsg9
                          ? _c("span", { staticClass: "ml-20" }, [
                              _vm._v("快递取消原因："),
                              _c(
                                "span",
                                { staticClass: "font-weight-bold color-red" },
                                [_vm._v(_vm._s(item.cancelMsg9))]
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  : _vm._e(),
                item.isOnline
                  ? _c(
                      "div",
                      { staticClass: "flex-start-center font-size-16 mt-10" },
                      [
                        item.makeAppointmentTime
                          ? _c("span", [
                              _vm._v("预约上门时间："),
                              _c(
                                "span",
                                { staticClass: "font-weight-bold color-blue" },
                                [_vm._v(_vm._s(item.makeAppointmentTime))]
                              )
                            ])
                          : _vm._e(),
                        item.createTime
                          ? _c("span", { staticClass: "ml-20" }, [
                              _vm._v("物流下单时间："),
                              _c(
                                "span",
                                { staticClass: "font-weight-bold color-black" },
                                [_vm._v(_vm._s(item.createTime))]
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  : _vm._e(),
                !item.isOnline
                  ? _c(
                      "div",
                      { staticClass: "flex-start-center font-size-16 mt-10" },
                      [
                        item.createTime
                          ? _c("span", [
                              _vm._v("线下发货时间："),
                              _c(
                                "span",
                                { staticClass: "font-weight-bold color-black" },
                                [_vm._v(_vm._s(item.createTime))]
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  : _vm._e()
              ]
            )
          }),
          _c("LogisticsInfoPopup", { ref: "logisticsInfoPopupEl" })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }