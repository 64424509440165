<template>
  <div>
    <a-modal
        :width="900"
        v-model="showPopup"
        title="物流信息"
        :footer="null"
        @cancel="handleCancel"
    >
      <div class="logistics-content">
        <div class="content-top" v-if="popupData">
          <div>
            <div class="top-user-info">
              <div class="top-user-text ml-20">物流公司：<span>{{ expressCompanyCodeMapText[popupData.com] }}</span></div>
              <div class="top-user-text ml-20">物流单号：<span>{{ popupData.nu }}</span></div>
            </div>
          </div>
        </div>
        <div class="content-time-line" v-if="popupData && popupData.data">
          <a-timeline>
            <a-timeline-item
                v-for="(item, index) in popupData.data"
                :key="index"
                :color="index === 0 ? 'blue' : 'gray'"
            >
              <p :class="index === 0 ? 'color-black' : ''">{{ item.context }}</p>
              <p class="color-gary">{{ item.time }}</p>
            </a-timeline-item>
          </a-timeline>
        </div>
        <div class="color-gary null-data" v-else>暂无物流信息~</div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import {getLogisticsDetailLine} from "@/views/cmsPage/shopManage/senderLogisticsList/_apis"
import {EXPRESS_COMPANY_CODE_MAP_TEXT} from "@/_data"

export default {
  components: {},
  data() {
    return {
      showPopup: false,
      popupData: '',
      expressNo: '',
      expressCompanyCodeMapText: EXPRESS_COMPANY_CODE_MAP_TEXT
    };
  },
  methods: {
    async show(data) {
      this.expressNo = data.expressNo
      await this.getLogisticsInfo()

    },
    /** 获取数据 */
    async getLogisticsInfo() {
      this.$loading.show()
      const res = await getLogisticsDetailLine({expressNo: this.expressNo})
      this.$loading.hide()
      if (res.status !== '200') return
      this.popupData = res.data
      this.showPopup = true
    },
    /** 取消 */
    handleCancel() {
      this.showPopup = false
      this.popupData = ''
    }
  },
};
</script>

<style lang="scss" scoped>
.null-data {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.content-time-line {
  margin-top: 20px;
  width: 100%;
}

.content-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .top-user-address {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 18px;
    color: gray;

    span {
      color: black;
      font-weight: bold;
      text-decoration: underline;
    }
  }

  .top-user-info {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    .top-user-text {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 18px;
      color: gray;

      span {
        color: #008a00;
        font-weight: bold;
      }
    }
  }
}

.logistics-content {
  width: 100%;
}
</style>
