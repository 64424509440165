
/** 搜索列表 */
import instance from "@/axios"

/** 获取物流信息 */
export const getLogisticsInfoApi = async (params) => {
    return instance({
        url: '/dq_admin/orderProductTool/getOrderLogisticsAddressList',
        method: 'GET',
        params: params
    })
}

/** 根据订单查询物流列表 */
export const getLogisticsDetailListByOrderApi = async (params) => {
    return instance({
        url: '/dq_admin/orderProductTool/getOrderLogisticsDetailsList',
        method: 'GET',
        params: params
    })
}

/** 查看物流详情 */
export const getLogisticsDetailLine = async (params) => {
    return instance({
        url: '/dq_admin/orderProductTool/queryLogisticsTrack',
        method: 'GET',
        params: params
    })
}

/** 查看物流详情 */
export const cancelUserSendProductApi = async (params) => {
    return instance({
        url: '/dq_admin/orderProductTool/orderProductReturns',
        method: 'GET',
        params: params
    })
}