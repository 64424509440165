var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "logistics-address-list-pages" },
    [
      _c(
        "div",
        { staticClass: "top-search-btn" },
        [
          _c("a-input", {
            staticClass: "w-200",
            attrs: { allowClear: "", placeholder: "搜索订单" },
            model: {
              value: _vm.searchOrderNo,
              callback: function($$v) {
                _vm.searchOrderNo = $$v
              },
              expression: "searchOrderNo"
            }
          }),
          _c(
            "a-button",
            {
              staticClass: "ml-20",
              attrs: { type: "primary" },
              on: { click: _vm.getInit }
            },
            [_vm._v("搜索")]
          ),
          _vm.orderStatusInfo.orderNo
            ? _c("span", { staticClass: "ml-40" }, [
                _vm._v("订单：【"),
                _c(
                  "span",
                  {
                    staticClass: "cur-pot color-blue",
                    on: { click: _vm.handleGoToOrderLis }
                  },
                  [_vm._v(_vm._s(_vm.orderStatusInfo.orderNo))]
                ),
                _vm._v("】")
              ])
            : _vm._e(),
          _vm.orderStatusInfo.statusDesc
            ? _c("span", { staticClass: "color-black ml-10" }, [
                _vm._v(_vm._s(_vm.orderStatusInfo.statusDesc))
              ])
            : _vm._e()
        ],
        1
      ),
      _c("LogisticsAddressList", {
        attrs: { list: _vm.logisticsAddressList },
        on: { editor: _vm.getInit }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }