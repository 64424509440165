<template>
  <a-modal
      :width="1000"
      v-model="showPopup"
      title="物流列表"
      :footer="null"
      @cancel="handleCancel"
  >
    <div class="w-100-w logistics-popup-list">
      <a-card
          v-for="(item, index) in listData"
          :key="index"
          class="w-100-w log-item"
      >
<!--    标题    -->
        <div slot="title" :class="item.isOnline ? 'color-blue' : 'color-red'">{{ item.isOnline ? '上门取件' : '线下手动' }}</div>
<!--    内容  -->
        <div class="flex-start-center font-size-16">
          <span class="font-weight-bold font-size-16 color-black">{{ expressCompanyCodeMapText[item.logisticsName] || item.logisticsName }}</span>
          【<span class="font-weight-bold font-size-16 color-green">{{ item.expressNo }}</span>】
          <span class="cur-pot color-blue" @click="handleShowLogisticsLine(item)">点击查看物流信息</span>
        </div>
<!--    内容    -->
        <div class="flex-start-center font-size-16 mt-10" v-if="item.isOnline">
          <span v-if="item.logisticsTotalAmount">快递费总金额：<span class="font-weight-bold color-red">￥{{ item.logisticsTotalAmount }}</span></span>
          <span v-if="item.commodityValue" class="ml-20">保价金额：<span class="font-weight-bold color-red">￥{{ item.commodityValue }}</span></span>
          <span v-if="item.txnSeqno" class="ml-20">交易单号：<span class="font-weight-bold color-orange cur-pot">{{ item.txnSeqno }}</span></span>
          <span v-if="item.logisticsPaymentStatus" class="ml-20">支付状态：<span class="font-weight-bold color-red">{{ item.logisticsPaymentStatus }}</span></span>
        </div>
<!--    内容    -->
        <div class="flex-start-center font-size-16 mt-10" v-if="item.isOnline">
          <span v-if="item.courierName">快递员姓名：<span class="font-weight-bold color-black">{{ item.courierName }}</span></span>
          <span v-if="item.courierMobile" class="ml-20">快递员电话：<span class="font-weight-bold color-blue">{{ item.courierMobile }}</span></span>
        </div>
 <!--    内容    -->
        <div class="flex-start-center font-size-16 mt-10" v-if="item.isOnline">
          <span v-if="item.statusDesc">快递状态：<span class="font-weight-bold color-black">{{ item.statusDesc }}</span></span>
          <span v-if="item.cancelMsg9" class="ml-20">快递取消原因：<span class="font-weight-bold color-red">{{ item.cancelMsg9 }}</span></span>
        </div>
<!--    内容    -->
        <div class="flex-start-center font-size-16 mt-10" v-if="item.isOnline">
          <span v-if="item.makeAppointmentTime">预约上门时间：<span class="font-weight-bold color-blue">{{ item.makeAppointmentTime }}</span></span>
          <span v-if="item.createTime" class="ml-20">物流下单时间：<span class="font-weight-bold color-black">{{ item.createTime }}</span></span>
        </div>
<!--    内容    -->
        <div class="flex-start-center font-size-16 mt-10" v-if="!item.isOnline">
          <span v-if="item.createTime">线下发货时间：<span class="font-weight-bold color-black">{{ item.createTime }}</span></span>
        </div>
      </a-card>
<!--   物流详情   -->
      <LogisticsInfoPopup ref="logisticsInfoPopupEl"/>
    </div>
  </a-modal>
</template>
<script>
import {getLogisticsDetailListByOrderApi} from "@/views/cmsPage/shopManage/senderLogisticsList/_apis"
import LogisticsInfoPopup from "@/views/cmsPage/shopManage/senderLogisticsList/_components/LogisticsInfoPopup/index.vue"
import {EXPRESS_COMPANY_CODE_MAP_TEXT} from "@/_data"

export default {
  components: {LogisticsInfoPopup},
  data() {
    return {
      expressCompanyCodeMapText: EXPRESS_COMPANY_CODE_MAP_TEXT,
      showPopup: false,
      listData: [],
      params: {
        orderId: 0,
        orderType: 0
      }
    }
  },
  methods: {
    /** 查看物流详细信息 */
    handleShowLogisticsLine(item) {
      this.$refs.logisticsInfoPopupEl.show(item)
    },
    async show(data) {
      this.params.orderId = data.orderId
      this.params.orderType = data.orderType
      await this.getLogisticsDetailList()
      this.showPopup = true
    },
    /** 获取详情物流列表 */
    async getLogisticsDetailList() {
      const res = await getLogisticsDetailListByOrderApi(this.params)
      if (res.status !== '200') return
      this.listData = res.data
    },
    /** 关闭弹窗 */
    handleCancel() {
      this.showPopup = false
      this.listData = []
      this.params = {
        orderId: 0,
        orderType: 0
      }
    }
  }
}
</script>
<style scoped lang="scss">
.log-item {
  width: 100%;
  margin-bottom: 20px;
}
</style>